.btn-xl{
  padding: 1.25rem 2.5rem !important;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
}

.btn-header{
    color: #fff !important;
    background-color: #003166 !important;
    border-color: #A42931 !important;
}
.btn-header:hover {
    color: #fff !important;
    background-color: #A42931 !important;
    border-color:  #003166 !important;
}